import './index.html';
import './style.scss';

import {gsap} from 'gsap';
import {imageLazyLoading} from '../../scripts/functions/imageLazyLoading';
import {animations} from '../../scripts/general/animations';
import {
  allowPageScroll,
  preventPageScroll
} from "../../scripts/functions/prevent_allowPageScroll";


export default async (block) => {
  // const tl = gsap.timeline({
  //   delay: 0.5, paused: true, defaults: {
  //     duration: 0.3
  //   }
  // })
  //   .fromTo(block.querySelectorAll('.links-wrapper'), {xPercent: 100}, {
  //     xPercent: 0,
  //     immediateRender: true
  //   })
  //   .fromTo(block.querySelectorAll('.links-wrapper .link-parent'), {
  //     y: 30,
  //     autoAlpha: 0,
  //   }, {
  //     y: 0,
  //     autoAlpha: 1,
  //     stagger: .05,
  //     duration: 0.2
  //   });
  block.querySelector(".menu").addEventListener("click", function () {
    // console.log('a7a');
    const isActive = block.classList.toggle("active");
    isActive ? preventPageScroll() : allowPageScroll()
    // isActive ? tl.play() : tl.reverse();
  })

  //region  on mouse enter pause scroll
  let notMobile = window.matchMedia('(min-width: 600px)');
  if (notMobile.matches) {
    block.addEventListener('mouseenter', () => {
      preventPageScroll()
    })
    block.addEventListener('mouseleave', () => {
      allowPageScroll()
    })
  }
  // endregion on mouse enter pause scroll

  // region open sub menu in responsive
  const mobileMedia = window.matchMedia('(max-width: 992px)');

  const menuItems = block.querySelectorAll('.links-wrapper .link-parent.has-subMenu');
  menuItems.forEach((menuItem) => {
    const menuItemBody = menuItem.querySelector('.drop-down-menu');
    menuItem?.addEventListener('click', (e) => {
      if (!mobileMedia.matches) return;
      if (!menuItemBody) {
        return;
      }
      const isOpened = menuItem?.classList.toggle('active-page');
      if (!isOpened) {
        gsap.to(menuItemBody, {height: 0});
      } else {
        gsap.to(Array.from(menuItems).map(otherFaq => {
          const otherFaqBody = otherFaq.querySelector('.drop-down-menu');
          if (otherFaqBody && menuItem !== otherFaq) {
            otherFaq?.classList.remove('active-page');
            gsap.set(otherFaq, {zIndex: 1});
          }
          return otherFaqBody;
        }), {height: 0});
        gsap.set(menuItem, {zIndex: 2});
        gsap.to(menuItemBody, {height: 'auto'});
      }
    });
  });
  // endregion open sub menu in responsive

  const menuLink = block.querySelectorAll(".link-parent");
  menuLink.forEach((link) => {
    link.addEventListener('click', function (e) {
      if (e.target.href !== '#' && e.target.href !== undefined) {
        block.classList.remove("active");
        allowPageScroll();
        // tl.reverse();
      }
      if (link.classList.contains('link-parent-active') && !link.classList.contains('has-subMenu')) {
        link.classList.remove('link-parent-active');
      } else {
        menuLink.forEach((link) => {
          link.classList.remove('link-parent-active');
        });
        link.classList.add('link-parent-active');
      }
    });
  });

  // disable link if it's same page
  let links = block.querySelectorAll('.header-link , .drop-down-menu a');
  let headerLogo = block.querySelector('.header-logo');
  let cbk = function (e) {
    if (e.target.parentElement.classList.contains('header-logo')) {
      allowPageScroll();
      block.classList.remove("active");
    }
    if (e.currentTarget.href === window.location.href || e.currentTarget.href === window.location.href + '#') {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  for (let link of links) {
    link?.addEventListener('click', cbk);
  }

  headerLogo?.addEventListener('click', cbk);

  animations(block);
  imageLazyLoading(block);
};

