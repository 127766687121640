import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import {SplitText} from 'gsap/SplitText';
import {getElementsForAnimation} from '../../functions/getElementsForAnimation';

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(SplitText);

const splitWords = element => {
  new SplitText(element, {
    type: 'words',
    wordsClass: 'word-overflow',
  });
  const splitWords = new SplitText(element, {
    type: 'words',
    wordsClass: 'child-word',
  });
  gsap.set(splitWords.words, {yPercent: 100});
  return splitWords;
}

export function blockCustomTextAnimation(container = document, selector = '.custom-text-animation') {
  const blocks = getElementsForAnimation(container, selector);
  if (!blocks) return;

  for (const block of blocks) {
    const title = splitWords(block.querySelectorAll('.title-animation'));
    const paragraph = block.querySelectorAll('.paragraph-animation');
    const button = block.querySelectorAll('.button-animation');

    gsap.timeline({
      scrollTrigger: {
        trigger: block,
        start: 'top 90%',
        once: true,
      }
    })
      .add(
        gsap.timeline()
          .to(title.words, {yPercent: 0, duration: 0.35, stagger: 0.05})
          .set(title.words.map(e => e.parentElement), {overflow: 'visible'}, '-=.2'),
        0)
      .fromTo(paragraph, {
        autoAlpha: 0,
        y: 80,
      }, {
        duration: .7,
        autoAlpha: 1,
        yPercent: 0,
        y: 0,
        ease: 'power1.out',
        stagger: .1,
        clearProps: 'transform',
      }, '<0.4')
      .fromTo(button, {
        autoAlpha: 0,
        y: 80,
      }, {
        duration: .7,
        autoAlpha: 1,
        yPercent: 0,
        y: 0,
        ease: 'power1.out',
        stagger: .1,
        clearProps: 'transform',
      }, '<0.1')
  }
}
