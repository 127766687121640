import {
  allowPageScroll,
  preventPageScroll
} from "../functions/prevent_allowPageScroll";
import {pageCleaningFunctions} from "./barba";

export function customModal(modalToggler, videoWrapper) {
  const customModalEl = document.getElementById('custom-modal');
  if (customModalEl) {
    const modalContent = customModalEl.querySelector('.custom-modal-wrap');
    // toggler function

    modalToggler?.addEventListener('click', () => {
      preventPageScroll();
      customModalEl.classList.add('active');
      modalContent.appendChild(videoWrapper);
    })
    videoWrapper.remove();
    videoWrapper.removeAttribute('modal-content');


    videoWrapper.remove();
    videoWrapper.removeAttribute('modal-content');

  }
}


export function initModal() {
  const customModalEl = document.getElementById('custom-modal');
  if (customModalEl) {

    const modalContent = customModalEl.querySelector('.custom-modal-wrap');
    const closeModal = () => {
      allowPageScroll();
      customModalEl.classList.remove('active');
    }
    const clearModal = () => {
      setTimeout(() => {
        modalContent.innerHTML = '';
      }, 500)
    }
    const keyHandler = (e) => {
      if (e.key === 'Escape') {
        closeModal();
        clearModal();
      }
    }
    window.addEventListener('keydown', keyHandler)

    customModalEl.addEventListener('click', () => {
      closeModal();
      clearModal();
    });
    customModalEl.querySelector('.custom-modal-inner').addEventListener('click', function (e) {
      e.stopPropagation();
    });
    customModalEl.querySelector('.close-modal').addEventListener('click', function (e) {
      console.log('a7a')
      closeModal();
      clearModal();
    });
  }
}
