import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export async function initBlocks(container) {

  const blocks = container.querySelectorAll('[data-section-class]');
  const preLoadedBlocks = 2;

  async function loadRemainingBlocks() {
    window.removeEventListener('scroll', loadRemainingBlocks);
    for (let i = 0; i < blocks.length - preLoadedBlocks; i++) {
      const block = blocks[i + preLoadedBlocks];
      const {default: blockScript} = await import('./' + block.dataset.sectionClass)
      block.classList.add('js-loaded')
      await blockScript(block);
    }

    const footer = container.querySelector('footer');
    if (footer) {
      const {default: blockScript} = await import('./footer_block');
      await blockScript(footer);
    }
    ScrollTrigger.refresh(false);
  }

  for (let i = 0; i < blocks.length; i++) {
    if (i < preLoadedBlocks && blocks[i].dataset.sectionClass !== 'hero_block') {
      const block = blocks[i];
      const {default: blockScript} = await import('./' + block.dataset.sectionClass)
      block.classList.add('js-loaded')
      await blockScript(block);
    }
  }
  await loadRemainingBlocks();
  // window.scrollY > 50 ? await loadRemainingBlocks() : window.addEventListener('scroll', loadRemainingBlocks);
}
