import {scrollToFunction} from "./scrollToFunction";

export function backToTop(container = document) {
  // let pageHeight = window.innerHeight;
  container.querySelector(".back-to-top")?.addEventListener("click", function () {
    scrollToFunction(document.scrollingElement || document.documentElement, "scrollTop", "", window.scrollY, 0, 500, true);
    // window.scrollTo({
    //   top: 0,
    //   left: 0,
    //   behavior: 'smooth'
    // });
  });
}
