export function cursorAnimation(container = document) {

  let projectBlocks = container.querySelectorAll('.project-card-image');


  for (const projectBlock of projectBlocks) {

    let follower, mouseX, mouseY, positionElement, printout, timer;

    follower = container.querySelector('.cursor-animation');

    mouseX = (event, rect) => {
      return event.clientX - rect.left;
    };

    mouseY = (event, rect) => {
      return event.clientY - rect.top;
    };

    positionElement = (event) => {
      const rect = projectBlock.getBoundingClientRect();
      follower.style.top = mouseY(event, rect) + 'px';
      follower.style.left = mouseX(event, rect) + 'px';
    };

    timer = false;


    projectBlock.addEventListener('mouseenter', (event) => {
      projectBlock.append(follower)
    })
    projectBlock.addEventListener('mousemove', (event) => {
      positionElement(event)
    })
    projectBlock.addEventListener('mouseleave', (event) => {
      document.body.querySelector('main').append(follower)
    })

  }
}
