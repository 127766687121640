import './styles/style.scss';
import {gsap} from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import header from './blocks/header_block';
import hero from './blocks/hero_block';
import {initBlocks} from './blocks';
import {barba} from "./scripts/general/barba";
import {breakLine} from './scripts/functions/breakLine';
import {getHeightOfViewPort} from './scripts/functions/getHeightOfViewPort';
import {setFormFunctionality} from './scripts/general/set-form-functionality';
import {generateDataHover} from './scripts/functions/generateDataHover';
import {scrollToHash} from './scripts/general/scroll-to-hash';
import {initModal} from "./scripts/general/custom-modal";
import {starAnimation} from "./scripts/general/star-animation";
import {filterArrow} from "./scripts/general/filter-arrow";
import {activePage} from "./scripts/general/active-page";
import {backToTop} from "./scripts/general/back-to-top";
import {VideoImage} from "./scripts/general/video-image";
import {cursorAnimation} from "./scripts/general/cursor-animation";
// import {SmoothScroll} from "./scripts/general/smooth-scroll";
// import {cursorCircle} from "./scripts/general/cursor-circle";
import {headerSticky} from "./scripts/general/header-sticky";


const reInvokableFunction = async (container = document) => {
  let notMobile = window.matchMedia('(min-width: 600px)');
  container.querySelector('.hero_block') && await hero(container.querySelector('.hero_block'));
  await initBlocks(container);
  headerSticky(container);
  setFormFunctionality(container);
  VideoImage(container);
  if (notMobile.matches) {
    starAnimation(container);
  }
  scrollToHash(container);
  cursorAnimation(container);
  filterArrow(container);
  activePage(container);
  backToTop(container);
  breakLine(container);
  generateDataHover(container);
  // cursorCircle(container);
  ScrollTrigger.refresh(true);
};

let loaded = false;

async function onLoad() {
  gsap.config({
    nullTargetWarn: false,
  });
  if (document.readyState === 'complete' && !loaded) {
    loaded = true;
    initModal();
    document.body.classList.add('loaded');
    gsap.registerPlugin(ScrollTrigger);
    getHeightOfViewPort();
    document.querySelector('header') && await header(document.querySelector('header'));
    await reInvokableFunction();
    barba(reInvokableFunction);
    // new SmoothScroll(document, 120, 12);
    // const addBarba = async () => (await import('./scripts/general/barbajs')).barbajs(reInvokableFunction);
    // window.addEventListener('scroll', addBarba)
    // window.removeEventListener('scroll', addBarba)

  }
}

onLoad();

document.onreadystatechange = () => onLoad();
