import {parallaxAnimation} from './parallaxAnimation';
import {imageRevealAnimation} from './imagesRevealAnimation';
import {wordsUpAnimation} from './wordsUpAnimation';
import {blockCustomTextAnimation} from './blockCustomTextAnimation';
import {linesUpAnimation} from './linesUpAnimation';
import {inViewAnimations} from './InViewAnimations';
import {realLinesUpAnimation} from './realLinesUpAnimation';
import {spriteSheetAnimation} from "./spriteSheetAnimation";
import {gsap} from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger)

const blocks = [];

export function animations(container = document) {
  // if (blocks.indexOf(container)>-1) return;
  // blocks.push(container);

  parallaxAnimation(container);
  imageRevealAnimation(container);
  wordsUpAnimation(container);
  blockCustomTextAnimation(container);
  linesUpAnimation(container);
  inViewAnimations(container);
  spriteSheetAnimation(container);
  setTimeout(function () {
    realLinesUpAnimation(container);
  }, 600);
}
