import './index.html';
import './style.scss';
import {scrollToFunction} from "../../scripts/general/scrollToFunction";
import {gsap} from "gsap";
import Parallax from 'parallax-js'
import {imageLazyLoading} from "../../scripts/functions/imageLazyLoading";
import {animations} from "../../scripts/general/animations";

const heroBlock = async (block) => {
  // play hero video with barba

  const heroVideo = block.querySelector('.hero-video');
  if (heroVideo) {
    heroVideo.play();
    heroVideo.addEventListener('ended', function () {
      setTimeout(() => {
        heroVideo.currentTime = 0;
        heroVideo.play();
      }, 4000)
    });
  }

  // region star animation
  // let tl = gsap.timeline({defaults: {transformOrigin: "center", duration: .5}})
  // tl.from(block.querySelector(".border-1"), {x: -50, y: -50, opacity: 0})
  //   .from(block.querySelector(".border-2"), {x: 50, y: 50, opacity: 0}, ">-.15")
  //   .from(block.querySelector(".border-3"), {
  //     x: -100,
  //     y: 100,
  //     opacity: 0
  //   }, ">-.15")
  //   .from(block.querySelector(".border-4"), {
  //     x: 100,
  //     y: -100,
  //     opacity: 0
  //   }, ">-.15")
  //   .from(block.querySelector(".circle"), {scale: 0, opacity: 0}, "<.35")
  //   .from(block.querySelector(".circle"), {
  //     rotate: 360,
  //     duration: 10,
  //     repeat: -1,
  //     ease: "linear"
  //   }, "<")
  //   .from(block.querySelector(".hero-icon"), {
  //     rotate: -360,
  //     duration: 10,
  //     repeat: -1,
  //     ease: "linear"
  //   }, "<");
  //endregion star animation
  // home hero logo animation

  gsap.from(block.querySelector('.hero-logo-wrapper'), {
    autoAlpha: 0,
    delay: 0.2
  })

  const smallCircle = block.querySelector(".small-circle");
  const nextSection = block.nextElementSibling;

  smallCircle.addEventListener("click", function () {
    if (nextSection.tagName === 'SECTION') {
      scrollToFunction(document.scrollingElement || document.documentElement, "scrollTop", "", window.scrollY, nextSection.offsetTop, 500, true);
      // nextSection.scrollIntoView({
      //   behavior: "smooth",
      //   block: "start",
      //   inline: "nearest"
      // });
    }
    // window.scrollTo({
    //   top: window.innerHeight * .9,
    //   behavior: "smooth"
    // })
  })


  smallCircle.addEventListener("mouseenter", function () {
    block.querySelector(".down-arrow").classList.add("grow");
    smallCircle.classList.add('stop-pulsing');
  })
  smallCircle.addEventListener("mouseleave", function () {
    block.querySelector(".down-arrow").classList.remove("grow");
    smallCircle.classList.remove('stop-pulsing');
  })

  let scene = block.querySelector('.ls-constellations.scene');
  let parallaxInstance = new Parallax(scene);


  animations(block);
  imageLazyLoading(block);
};

export default heroBlock;

