import gsap from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
export function filterArrow(container = document) {
  let filterArrows = container.querySelectorAll('.filter-arrow');
  if (filterArrows.length === 0) return;
  for (let filterArrow of filterArrows) {
    ScrollTrigger.create({
      trigger: filterArrow,
      start: 'top 60%',
      end: 'bottom bottom',
      once: true,
      onEnter() {
        filterArrow.classList.add('filter-arrow-draw');
      }
    })
  }
}
