import barbajs from '@barba/core';
import {gsap} from 'gsap';

import ScrollTrigger from 'gsap/ScrollTrigger';
//region prev ver
// export const pageCleaningFunctions = [];
// const clip = {top: 0, bottom: 0};
// const crossFade = {
//   name: 'bottom-overlay-color-transition',
//
//   leave(data) {
//     return gsap.to(data.current.container.querySelector('.page-transition'), {opacity: 0});
//   },
//   enter(data) {
//     gsap.set(data.next.container, {opacity: 0});
//     gsap.set(data.current.container, {
//       zIndex: -1,
//       position: 'absolute',
//       width: '100%'
//     });
//     return gsap.timeline()
//       .to(data.next.container, {opacity: 1, delay: 1})
//   },
// };
// const clippedCover = {
//   name: 'clipped-cover-color-transition',
//
//   leave(data) {
//     const cover = document.querySelector('.page-transition');
//     return gsap.timeline({
//       onUpdate() {
//         cover.style.clipPath = `polygon(0% 0%, 0% 100%, ${clip.bottom}% 100%, ${clip.top}% 0%)`;
//       }
//     })
//       .fromTo(clip, {top: 0}, {top: 100})
//       .fromTo(clip, {bottom: 0}, {bottom: 100}, '<15%')
//       .to(cover.querySelector('.shine-effect'), {left: '125%'})
//       .call(() => window.scrollTo(0, 0))
//   },
//   enter(data) {
//     gsap.set(data.next.container, {opacity: 0});
//     gsap.set(data.current.container, {zIndex: -1, position: 'absolute'});
//     // window.scrollTo(0, 0);
//     // console.log('window.location.hash:', window.location.hash);
//     // window.location.hash && window.scrollTo(0, window.scrollTop + data.next.container.querySelector(window.location.hash)?.getBoundingClientRect().top || 0)
//     const cover = document.querySelector('.page-transition');
//     return gsap.timeline({
//       onUpdate() {
//         cover.style.clipPath = `polygon(0% 0%, 0% 100%, ${clip.bottom}% 100%, ${clip.top}% 0%)`;
//       },
//     })
//       .set(data.next.container, {opacity: 1})
//       .set(data.current.container, {opacity: 0})
//       .call(() => window.scrollTo(0, 0))
//       .to(cover, {backgroundColor: '#010214'})
//       .fromTo(clip, {top: 100}, {top: 0})
//       .fromTo(clip, {bottom: 100}, {bottom: 0}, '<25%')
//       .to(cover.querySelector('.shine-effect'), {left: '-85%'})
//   },
// };
// export const barba = (reInvokableFunction) => {
//   gsap.registerPlugin(ScrollTrigger);
//   if (document.querySelector('[data-barba]')) {
//     barbajs.init({
//       transitions: [clippedCover],
//       timeout: 0,
//       prevent: ({el}) => el.classList?.contains('ab-item'),
//     });
//
//     barbajs.hooks.afterLeave(() => {
//       // window.scrollTo(0, 0);
//       window.dispatchEvent(new Event('will-leave'));
//       for (let scrollTrigger of ScrollTrigger.getAll()) {
//         scrollTrigger.kill();
//       }
//       while (pageCleaningFunctions.length) {
//         pageCleaningFunctions.pop()();
//       }
//       ScrollTrigger.update();
//     });
//
//     barbajs.hooks.beforeEnter(data => {
//       // window.scrollTo(0, 0);
//       reInvokableFunction(data.next.container);
//     });
//     // barbajs.hooks.afterEnter(({current}) => {
//     //   window.scrollTo(0, 0);
//     // });
//
//   } else {
//     console.log('no barbajs container');
//   }
// }
//endregion prev ver
let popState = {'status': false};
window.addEventListener('popstate', () => {
  popState.status = true;
});
export const pageCleaningFunctions = [];
const clip = {top: 0, bottom: 0};
const crossFade = {
  name: 'bottom-overlay-color-transition',
  leave(data) {
    const headerBackground = document.querySelector('.sticky-background');
    headerBackground.style.transform = "translateY(-110%)";
    document.body.classList.remove('single-post'); // remove single post from body to fix header styling
    // headerBackground.style.removeProperty('transform');
    const cover = document.querySelector('.page-transition');
    return gsap.timeline()
      .to(cover, {opacity: 1})
      .to(headerBackground, {clearProps: true})
      .call(() => popState.status ? (popState.status = false) : window.scrollTo(0, 0))
    // .call(() => popState.status || window.scrollTo(0, 0))
  },
  enter(data) {
    gsap.set(data.next.container, {opacity: 0});
    gsap.set(data.current.container, {zIndex: -1, position: 'absolute'});
    const cover = document.querySelector('.page-transition');
    return gsap.timeline()
      .set(data.next.container, {opacity: 1})
      .set(data.current.container, {opacity: 0})
      // .call(() => popState.status ? (popState.status = false) : window.scrollTo(0, 0))
      .to(cover, {opacity: 0})
  },
  afterLeave(data) {
    let regexp = /\<body.*\sclass=["'](.+?)["'].*\>/gi,
      match = regexp.exec(data.next.html);
    if (!match || !match[1]) {
      // If no body class, remove it
      document.body.setAttribute("class", "loaded");
    } else {
      // Set the new body class
      document.body.setAttribute("class", `${match[1]} loaded`);
    }
  }
};
let projectImage;
let projectVideo;
const projectEffect = {
  name: 'project-effect',
  to: {
    namespace: [
      'project'
    ]
  },
  leave(data) {
    console.log('Barba Project Leave')
    projectVideo = data.trigger.closest('.project-barba').querySelector('video');
    if (projectVideo) {
      projectImage = projectVideo;
    } else {
      projectImage = data.trigger.closest('.project-barba').querySelector('img');
    }
    const {width, height, left, top} = projectImage.getBoundingClientRect();

    return gsap.timeline({defaults: {duration: .5, ease: "back.in(1)"}})
      .set(projectImage, {
        width,
        height,
        top,
        left,
        position: 'fixed',
        zIndex: 9999999,
      })
      .call(() => {
        document.body.append(projectImage)
      })
      .to(projectImage, {
        width: '100vw',
        height: '90vh',
        top: 0,
        left: 0,
        objectFit: 'cover',
        duration: .9,
      })
      .to(data.current.container, {opacity: 0, duration: 1.2}, '<')
      .call(() => {
        window.scrollTo(0, 0)
      })
  },
  enter(data) {
    // add class for elements to not animate in it's block
    for (let ivStFromBottomCustom of data.next.container.querySelectorAll('.iv-st-from-bottom-custom')) {
      ivStFromBottomCustom.classList.add('animated');
    }
    // add class for elements to not animate in it's block
    if (projectVideo) {
      gsap.set(data.next.container, {opacity: 0});
      gsap.set(data.current.container, {zIndex: -1, position: 'absolute'});
      const heroVideo = data.next.container.querySelector('.project-hero-video');
      // const heroImage = data.next.container.querySelector('.section-bg-img');
      const {width, height, left, top} = heroVideo.getBoundingClientRect();
      return gsap.timeline()
        .set(data.current.container, {opacity: 0})
        .addLabel('loaded')
        .to(data.next.container, {opacity: 1, duration: .2}, 'loaded')
        .to(projectImage, {opacity: 0}, 'a7a')
        .call(() => {
          heroVideo.currentTime = projectVideo.currentTime
          heroVideo.play()
        }, '', 'a7a')
        .fromTo(data.next.container.querySelectorAll('.iv-st-from-bottom-custom'), {
          autoAlpha: 0,
          y: (_, target) => 80 * (target.classList.contains('iv-st-from-bottom-custom') ? 1 : -1),
        }, {
          duration: .7,
          autoAlpha: 1,
          y: 0,
          ease: 'power1.out',
          stagger: .1,
          clearProps: 'transform',
        })
        .call(() => {
          window.scrollTo(0, 0);
          projectImage.remove();
        })
    } else {
      gsap.set(data.next.container, {opacity: 0});
      gsap.set(data.current.container, {zIndex: -1, position: 'absolute'});
      const heroImage = data.next.container.querySelector('.section-bg-img');
      const {width, height, left, top} = heroImage.getBoundingClientRect();
      return gsap.timeline()
        .set(data.current.container, {opacity: 0})
        .addLabel('loaded')
        .to(data.next.container, {opacity: 1, duration: .2}, 'loaded')
        .to(projectImage, {opacity: 0})
        .fromTo(data.next.container.querySelectorAll('.iv-st-from-bottom-custom'), {
          autoAlpha: 0,
          y: (_, target) => 80 * (target.classList.contains('iv-st-from-bottom-custom') ? 1 : -1),
        }, {
          duration: .7,
          autoAlpha: 1,
          y: 0,
          ease: 'power1.out',
          stagger: .1,
          clearProps: 'transform',
        })
        .call(() => {
          window.scrollTo(0, 0);
          projectImage.remove();
        })
    }
  },
};
export const barba = (reInvokableFunction) => {
  gsap.registerPlugin(ScrollTrigger);
  if (document.querySelector('[data-barba]')) {
    barbajs.init({
      transitions: [crossFade, projectEffect],
      timeout: 0,
      prevent: ({el}) => el.classList?.contains('ab-item'),
    });

    barbajs.hooks.afterLeave(() => {
      // window.scrollTo(0, 0);
      window.dispatchEvent(new Event('will-leave'));
      for (let scrollTrigger of ScrollTrigger.getAll()) {
        scrollTrigger.kill();
      }
      while (pageCleaningFunctions.length) {
        pageCleaningFunctions.pop()();
      }
      ScrollTrigger.update();
    });

    barbajs.hooks.beforeEnter(data => {
      // window.scrollTo(0, 0);
      reInvokableFunction(data.next.container);
    });
    // barbajs.hooks.afterEnter(({current}) => {
    //   window.scrollTo(0, 0);
    // });

  } else {
    console.log('no barbajs container');
  }
}
