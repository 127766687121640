import {gsap} from "gsap/gsap-core";
import {ScrollTrigger} from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export function starAnimation(container = document) {
  let isSafari = window.safari !== undefined;
  let safariChecker = isSafari ? {transformBox: 'fill-box',} : {svgOrigin: 'center',}
  let stars = container.querySelectorAll('.star');
  for (const star of stars) {
    gsap.timeline({
      scrollTrigger: {
        trigger: star,
        start: 'center 90%'
        // toggleActions: "play none none none"
      }
    })
      .set(star.children, {overflow: 'visible'})
      .from(star.querySelectorAll(".star-shape"), {
        scale: 0,
        // y: -20,
        duration: 0.4,
        ...safariChecker,
        ease: 'none'
      })
    // .from(star, {
    //   // rotate: 180,
    //   transformOrigin: "center",
    //   duration: .8
    // }, "<")
  }
}
