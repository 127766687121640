export function activePage() {
  let links = document.querySelectorAll('.header-link');
  let dropDownLinks = document.querySelectorAll('.drop-down-menu a');
  for (let link of links) {
    link.parentElement.classList.remove('link-parent-active');
    if (link.href === window.location.href) {
      link.parentElement.classList.add('link-parent-active');
    }
  }
  for (let dropDownLink of dropDownLinks) {
    if (dropDownLink.href === window.location.href) {
      dropDownLink.closest('.link-parent').classList.add('link-parent-active');
    }
  }
}
