import {gsap} from "gsap/gsap-core";
import {ScrollTrigger} from "gsap/ScrollTrigger";

export function VideoImage(container = document) {
  let projectImages = container.querySelectorAll('.project-card-image');
  let cursorAnimation = container.querySelector('.cursor-animation');
  // let cursorCircle = container.querySelector('#cursor-circle');
  gsap.registerPlugin(ScrollTrigger);

  for (const projectImage of projectImages) {
    let isVideo = projectImage.classList.contains('video-image');
    let video = isVideo && projectImage.querySelector('video');
    projectImage.addEventListener('mouseenter', function () {
      console.log('Video Image Mouse Enter')
      isVideo && video.play();
      cursorAnimation.classList.remove('cursor-animation-hide');
      isVideo && projectImage.classList.add('playing');
      isVideo && video.setAttribute("autoplay", "autoplay");
      // cursorCircle.style.transitionDelay = '0s'
      // cursorCircle.style.opacity = 0;
    })
    projectImage.addEventListener('mouseleave', function () {
      // isVideo && !projectImage.classList.contains('featured-project-video') ? video.pause() : '';
      isVideo && video.pause();
      cursorAnimation.classList.add('cursor-animation-hide');
      isVideo && projectImage.classList.remove('playing');
      // cursorCircle.style.opacity = 1;
    })
  }
}
