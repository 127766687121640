export function headerSticky(container = document) {
  // header sticky
  const hero = container.querySelector('.hero_block');
  const header = document.querySelector('header');
  window.addEventListener('scroll', function () {
    if (hero) {
      header.classList.toggle('sticky', window.scrollY >= hero.offsetHeight - 150);
    } else {
      header.classList.toggle('sticky', window.scrollY >= 20);
    }
  });

}
